<template>
  <div class="content_body AppointmentConfig">
    <el-tabs v-model="handleIndex" @tab-click="handleClick">
      <el-tab-pane label="排班管理" name="2">
        <el-row>
          <el-col :span="20">
            <el-form :inline="true" size="small">
              <!-- <el-form-item label="选择职务">
                <el-select v-model="value" multiple placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="时间">
                <el-date-picker v-model="AppointmentMonth" type="month" :clearable="false" placeholder="选择月" @change="AppointmentChangeMonth"></el-date-picker>
              </el-form-item>

              <!-- <el-form-item label="员工">
                <el-input v-model="searchEmpName" placeholder="输入员工工号、姓名、手机号" clearable ></el-input>
              </el-form-item> -->

              <el-form-item>
                <el-button type="primary" @click="handleSearchSchedule" v-prevent-click>搜索</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="4" class="text_right">
            <el-button type="primary" size="small" @click="setingVisible = true" v-prevent-click>排班设置</el-button>
          </el-col>
        </el-row>

        <el-table size="small" border :data="empScheduleData" :header-row-class-name="'customTableHeader'" :row-class-name="customRowClass" :cell-class-name="'customTableCellClass'" :max-height="scheduleTableHeight">
          <el-table-column :render-header="customEmpLabelHead" fixed label="美容师" prop="EmployeeName" width="90"></el-table-column>
          <el-table-column v-for="(item,index) in AppointmentDayAndWeek" :key="index" :label="item.value" :render-header="customLabelHead" width="50">

            <template slot-scope="scope">
              <div :class="cusotmCellContentDivClass(scope.row,index)" @click="empScheduleCellClick(scope.row,index)" @mousedown="longTouchStartClick(scope.row,scope.column,index)" @mouseup="onmouseupEvent(scope.row,index)" @mouseenter="onmousemoveEvent(scope.row,scope.column,index)">
                {{scope.row.Schedule[index].IsRest ?'休息': scope.row.Schedule[index].AppointmentScheduleName}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="班次管理" name="1">
        <el-row align="middle">
          <el-col :span="20" class=" pad_5">
          </el-col>
          <el-col :span="4" class="text_right ">
            <el-button type="primary" size="small" @click="updateScheduleClick(0)">添加班次</el-button>
          </el-col>
        </el-row>
        <el-table :data="scheduleList" style="width: 100%" class="martp_10" size="small">
          <el-table-column prop="Name" label="班次" size="small"></el-table-column>
          <el-table-column :formatter="schedulTimeFormatter" label="班次时间" size="small"></el-table-column>
          <el-table-column prop="date" label="操作" width="180" size="small" align="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="updateScheduleClick(1,scope.row)">修改</el-button>
              <el-button type="danger" size="small" @click="DeleteAppointmentScheduleNet(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="预约设置" name="0">
        <el-form ref="AppointmentConfigInfoRef" :model="AppointmentConfigInfo" label-width="120px" :rules="AppointmentConfigInfoRules">
          <el-form-item required label="预约时间: ">
            <el-col :span="4">
              <el-form-item prop="StartTime">
                <el-time-select size="small" align="center" v-model="AppointmentConfigInfo.StartTime" :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:50'
                  }" placeholder="选择时间"></el-time-select>
              </el-form-item>
            </el-col>
            <el-col class="text_center" :span="1">至</el-col>
            <el-col :span="4">
              <el-form-item prop="EndTime">
                <el-time-select size="small" align="center" v-model="AppointmentConfigInfo.EndTime" :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:50'
                  }" placeholder="选择时间"></el-time-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="Period" label="时间间隔: ">
            <el-select v-model="AppointmentConfigInfo.Period" placeholder="请选择时间间隔" size="small">
              <el-option v-for="item in intervalTimeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item required label="员工选择: ">
            <el-radio-group class="dis_flex flex_dir_column" v-model="AppointmentConfigInfo.IsMustEmployee">
              <el-radio :label="true" class="martp_10">添加预约时，必选员工</el-radio>
              <el-radio :label="false" class="martp_10">添加预约时，不必选员工</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item required label="员工休息: ">
            <el-radio-group class="dis_flex flex_dir_column" v-model="AppointmentConfigInfo.CanChooseRestEmployee">
              <el-radio :label="true" class="martp_10">可预约休息的员工</el-radio>
              <el-radio :label="false" class="martp_10">不可预约休息的员工</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="updateAppointmentConfigNet(AppointmentConfigInfo)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 添加 -- 修改 --- 班次 -->
    <el-dialog title="添加班次" :visible.sync="orderOfClassesVisible" width="600px">
      <div>
        <el-form ref="currentScheduleInfoRef" :model="currentScheduleInfo" label-width="80px" :rules="currentScheduleInfoRules">
          <el-form-item label="班次名称" prop="Name">
            <el-input size="small" v-model="currentScheduleInfo.Name" clearable></el-input>
          </el-form-item>
          <el-form-item label="班次时间" prop="currentScheduleTime">
            <el-time-picker size="small" format="HH:mm" is-range v-model="currentScheduleInfo.currentScheduleTime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelOrderOfClassesVisible" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" v-prevent-click @click="saveScheduleInfo">保存</el-button>
      </span>
    </el-dialog>
    <!-- 排班设置 -->
    <el-dialog :visible.sync="setingVisible" width="600px">
      <div slot="title" class="dis_flex flex_dir_column">
        <span class="font_18">排班设置</span>
        <span class="font_13 color_gray martp_10">排班周期切换时，多出的周期班次将默认设置为休息，请及时更新员工排班信息。</span>
      </div>
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="排班:">
            <el-radio-group v-model="empScheduleForType" class="dis_flex flex_dir_column">
              <el-radio label="0" class="martp_10">手动设置排班</el-radio>
              <el-radio label="1" class="martp_10">单周 按周一到周日循环排班</el-radio>
              <el-radio label="2" class="martp_10">月 按自然月循环排班</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setingVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button @click="saveScheduleTypeClick" type="primary" size="small" v-prevent-click>保存</el-button>
      </span>
    </el-dialog>
    <!-- 编辑排班  -->
    <el-dialog title="编辑排班" :visible.sync="eidtVisible" width="600px">
      <div>
        <el-form ref="eidtScheduleImfoForm" label-width="80px" :model="ScheduleInfoFrom">
          <el-form-item label="员工:">{{ScheduleInfoFrom.empEditName}}</el-form-item>
          <el-form-item label="时间:">{{ScheduleInfoFrom.empEditDate}} 号</el-form-item>
          <el-form-item label="排班:" prop="empEditType" :rules="[{ required: true, message: '请选择班次', trigger: 'change' }]">
            <el-radio-group v-model="ScheduleInfoFrom.empEditType" class="dis_flex flex_dir_column">
              <el-radio :label="item.ID" class="martp_15" v-for="(item,index) in scheduleList" :key="index">{{item.Name}} ({{item.StartTime}}~{{item.EndTime}}) </el-radio>
              <el-radio label="10086" class="martp_10">休息</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="eidtVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button @click="saveScheduleInfoClick" type="primary" size="small" v-prevent-click>保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/Appointment/appointmentConfig";
import dateTime from "@/components/js/date";

var Enumerable = require("linq");

export default {
  name: "AppointmentConfig",
  data() {
    return {
      shouldShowDialog: false,
      handleIndex: "2",
      disabled: false, // 是否可选
      orderOfClassesVisible: false,
      setingVisible: false,
      eidtVisible: false,
      entityList: [],
      searchEmpName: "",
      tableData: [
        {
          Name: "班次",
          StartTime: "10:00",
          EndTime: "12:00",
        },
      ],
      intervalTimeOptions: [
        {
          value: "15",
          label: "15",
        },
        {
          value: "30",
          label: "30",
        },
      ],
      value: "",
      AppointmentMonth: new Date(),
      AppointmentLastMonth: new Date(),
      AppointmentDayAndWeek: [],
      AppointmentConfigInfo: {}, //预约设置信息
      AppointmentConfigInfoRules: {
        StartTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        EndTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        Period: [
          { required: true, message: "请选择接待人", trigger: "change" },
        ],
      },
      scheduleList: [], //班次列表
      empScheduleData: [], //请求的排班信息
      currentScheduleInfo: {
        Name: "",
        currentScheduleTime: [new Date(), dateTime.getCurrentDateAfterHour(6)],
      }, //将要修改的班次信息
      currentScheduleInfoRules: {
        Name: [
          { required: true, message: "请输入班次名称", trigger: "change" },
        ],
        currentScheduleTime: [
          { required: true, message: "请选择班次时间", trigger: "change" },
        ],
      },
      updateScheduleType: 0,
      empScheduleForType: "0", //员工循环排班类型 ，0-手动设置。1-按周循环。2-按月循环

      ScheduleInfoFrom: {
        empEditName: "", //排班时员工名、
        empEditDate: "", //排班时 的时间
        empEditID: "", //排班时员工ID
        empEditType: 0, //员工排班界面的 radio 绑定值
      },
      scheduleTableHeight: 0,
      startLong: false, //开始长按
      startLongDate: null, //开始长按的时间
      startRowIndex: 0, // 开始长按的row Index
      multipleEmpScheduleParams: [], //员工多选排班的参数
      isMultipleEmp: false, //是否多选员工
      weekDays: [], //按周循环 周一到周日的日期
    };
  },
  methods: {
    /**  tab 点击切换  */
    handleClick() {
      var that = this;
    },

    // 树形结构数据转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },

    /**  点击事件  */
    /**  修改月份 计算日期及 周几  */
    AppointmentChangeMonth() {
      var that = this;

      that.AppointmentDayAndWeek = [];
      var year = that.AppointmentMonth.getFullYear();
      var month = that.AppointmentMonth.getMonth();
      var monthDays = new Date(year, month + 1, 0).getDate();

      // console.log(dateTime.formatDate.format(new Date(year, month + 1, 0),"YYYY-MM-DD"));
      /**  赋值 日期 周期  */
      for (let i = 1; i <= monthDays; i++) {
        var newDate = new Date(year, month, i);
        var week = dateTime.getWeek(newDate);
        if (i < 10) {
          i = "0" + i;
        }
        that.AppointmentDayAndWeek.push({
          day: i,
          week: week,
          AppointmentScheduleID: null,
          AppointmentScheduleName: "",
          ScheduleDate:
            dateTime.formatDate.format(that.AppointmentMonth, "YYYY-MM") +
            "-" +
            i,
          IsRest: false,
          isSelect: false,
        });
      }
      (that.empScheduleData = []), that.getAppointmentEmployeeSchedule();
    },

    /**  自定义员工排班日期表头  */
    customLabelHead(h, { column, $index }) {
      var that = this;
      var item = that.AppointmentDayAndWeek[$index - 1];
      return [
        h("span", { class: " font_14 font_weight_300" }, item.day),
        h("span", { class: " font_14 font_weight_300" }, item.week),
      ];
    },
    /** 自定义员工表头   */
    customEmpLabelHead(h, { column, $index }) {
      return h("div", { class: "tabHeaderDiagonal" });
    },
    /**  员工排班 设置表头 格式  */
    customTableHeader({ row, rowIndex }) {
      row.index = rowIndex;
      return "customTableHeader";
    },
    /** 设置 索引  行列的索引  */
    customRowClass({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
    },

    /** 设置排班的row-keys   */
    getScheduleRowKeys(row) {
      return row.EmployeeID;
    },
    /**  设置排班 状态 获取上月排班信息 */
    saveScheduleTypeClick() {
      var that = this;
      that.setingVisible = false;
      if (that.empScheduleForType == "2") {
        that.AppointmentLastMonth = new Date(that.AppointmentMonth);
        that.AppointmentLastMonth = new Date(
          that.AppointmentLastMonth.setMonth(
            that.AppointmentLastMonth.getMonth() - 1
          )
        );
        that.getAppointmentEmployeeSchedule();
      }
    },
    /**  开始长按  */
    longTouchStartClick(row, column, index) {
      // console.log("longTouchStartClick -> row", row)
      // console.log("longTouchStartClick -> column", column)
      var that = this;
      that.isMultipleEmp = false;
      var sche = row.Schedule[index];
      that.startLongDate = sche.ScheduleDate;
      that.startRowIndex = row.index;
      that.empScheduleData.forEach((emp) => {
        emp.Schedule.forEach((sch) => {
          sch.isSelect = false;
          that.$set(sch, { isSelect: false });
        });
      });

      that.startLong = true;
      row.Schedule[index].isSelect = true;
      that.$set(row.Schedule[index], { isSelect: true });
    },

    /**  鼠标松开时  */
    onmouseupEvent(row, index) {
      var that = this;
      that.startLong = false;
      // row.Schedule[index].isSelect = false
      var endTime = row.Schedule[index].ScheduleDate;
      var endDate = dateTime.convertDateFromString(endTime);
      var currentDate = new Date();
      if (endDate < currentDate) {
        that.$message.error({
          message: "排班日期不能小于当前日期",
          duration: 2000,
        });
        return;
      }
      var empNames = [];
      var currentRowIndex = row.index;
      that.ScheduleInfoFrom.empEditDate =
        that.startLongDate.split("-")[2] +
        " ~ " +
        row.Schedule[index].ScheduleDate.split("-")[2];
      that.multipleEmpScheduleParams = [];

      that.empScheduleData.forEach((emp, index) => {
        if (that.startRowIndex <= index && index <= currentRowIndex) {
          empNames.push(emp.EmployeeName); //添加员工名称
          var empParam = {
            EmployeeID: emp.EmployeeID,
            Schedule: [],
          };
          emp.Schedule.forEach((sch) => {
            if (sch.isSelect) {
              empParam.Schedule.push({
                ScheduleDate: sch.ScheduleDate,
                AppointmentScheduleID: sch.AppointmentScheduleID,
                IsRest: sch.IsRest,
              });
            }
          });
          that.multipleEmpScheduleParams.push(empParam);
        }
      });
      that.ScheduleInfoFrom.empEditName = empNames.join(" , ");
      that.eidtVisible = true;
    },
    /**  鼠标移入 cell  */
    onmousemoveEvent(row, column, index) {
      var that = this;

      if (that.startLong) {
        that.isMultipleEmp = true;
        var endSchDate = row.Schedule[index].ScheduleDate;
        var startTime = new Date(Date.parse(that.startLongDate));
        var endTime = new Date(Date.parse(endSchDate));
        var currentRowIndex = row.index;

        that.empScheduleData.forEach((emp, index) => {
          if (that.startRowIndex <= index && index <= currentRowIndex) {
            emp.Schedule.forEach((sch) => {
              sch.isSelect = false;
              var currentTime = new Date(Date.parse(sch.ScheduleDate));
              if (startTime <= currentTime && currentTime <= endTime) {
                sch.isSelect = true;
              } else {
                sch.isSelect = false;
              }
            });
          } else {
            emp.Schedule.forEach((sch) => {
              sch.isSelect = false;
            });
          }
        });
      }
    },

    /**  自定义排班 的class   */
    cusotmCellContentDivClass(row, index) {
      var cla_1 = row.Schedule[index].isSelect
        ? "cell_select_content"
        : "cell_content";
      var cla_2 = row.Schedule[index].IsRest ? "empIsRest" : "empIsRestMistake";
      return cla_1 + " " + cla_2;
    },

    /** cell 点击事件 点击排班   */
    empScheduleCellClick(row, index) {
      var that = this;
      var selectDate = dateTime.convertDateFromString(
        row.Schedule[index].ScheduleDate
      );
      var currentDate = new Date();
      if (selectDate < currentDate) {
        // that.$message.error({
        //   message:"排班日期不能小于当前日期",
        //   duration:2000,
        // })
        return;
      }

      that.eidtVisible = true;
      that.empEditID = row.EmployeeID;
      var empName = row.EmployeeName;
      var day = row.Schedule[index].day;
      that.weekDays = [];
      switch (that.empScheduleForType) {
        case "0": //手动设置
          that.ScheduleInfoFrom.empEditName = empName;
          // that.empEditDate = day;
          that.weekDays.push(day);

          break;
        case "1": //按周循环
          that.weekDays = dateTime.getAWeedkYMD(selectDate);
          that.ScheduleInfoFrom.empEditName = empName;
          that.ScheduleInfoFrom.empEditDate =
            that.weekDays[0] + "~" + that.weekDays[that.weekDays.length - 1];

          break;
        case "2": //按月循环
          break;

        default:
          break;
      }
    },

    /**  保存排版信息  */
    saveScheduleInfoClick() {
      var that = this;
      that.eidtVisible = false;
      var AppointmentScheduleID = null;
      var IsRest = false;
      that.$refs["eidtScheduleImfoForm"].validate((valid) => {
        if (valid) {
          /**  判断是否休息  */
          if (that.ScheduleInfoFrom.empEditType == "10086") {
            //休息
            IsRest = true;
          } else {
            //其他
            AppointmentScheduleID = that.ScheduleInfoFrom.empEditType;
          }
          var params = [];
          if (that.isMultipleEmp) {
            that.multipleEmpScheduleParams.forEach((emp) => {
              emp.Schedule.forEach((sch) => {
                (sch.AppointmentScheduleID = AppointmentScheduleID),
                  (sch.IsRest = IsRest);
              });
            });
            params = that.multipleEmpScheduleParams;
          } else {
            that.empScheduleData.forEach((emp) => {
              if (emp.EmployeeID == that.empEditID) {
                var empSch = {
                  EmployeeID: emp.EmployeeID,
                  Schedule: [],
                };
                emp.Schedule.forEach((time) => {
                  that.weekDays.forEach((day) => {
                    if (time.day == day) {
                      empSch.Schedule.push({
                        ScheduleDate: time.ScheduleDate,
                        AppointmentScheduleID: AppointmentScheduleID,
                        IsRest: IsRest,
                      });
                    }
                  });
                });
                params.push(empSch);
              }
            });
          }

          that.saveAppointmentEmployeeSchedule(params);
        }
      });
    },

    /**  格式化 班次设置时间  */
    schedulTimeFormatter(row) {
      return row.StartTime + " ~ " + row.EndTime;
    },
    /** 添加 修改班次  */
    updateScheduleClick(type, row) {
      var that = this;
      that.orderOfClassesVisible = true;
      that.updateScheduleType = type;
      if (type == 0) {
        that.currentScheduleInfo = {};
      } else {
        // that.currentScheduleInfo = row;
        var StartTime = new Date();
        StartTime.setHours(row.StartTime.split(":")[0]);
        StartTime.setMinutes(row.StartTime.split(":")[1]);
        var EndTime = new Date();
        EndTime.setHours(row.EndTime.split(":")[0]);
        EndTime.setMinutes(row.EndTime.split(":")[1]);

        that.currentScheduleInfo = {
          Name: row.Name,
          currentScheduleTime: [StartTime, EndTime],
          ID: row.ID,
        };
      }
    },
    /**    */
    cancelOrderOfClassesVisible() {
      var that = this;
      that.orderOfClassesVisible = false;
      that.$refs["currentScheduleInfoRef"].resetFields();
    },
    /**  保存班次信息  */
    saveScheduleInfo() {
      var that = this;
      that.$refs["currentScheduleInfoRef"].validate((valid) => {
        if (valid) {
          that.orderOfClassesVisible = false;

          if (that.updateScheduleType == 0) {
            var StartTime = dateTime.formatDate.format(
              that.currentScheduleInfo.currentScheduleTime[0],
              "hh:mm"
            );
            var EndTime = dateTime.formatDate.format(
              that.currentScheduleInfo.currentScheduleTime[1],
              "hh:mm"
            );
            var params = {
              Name: that.currentScheduleInfo.Name,
              StartTime: StartTime,
              EndTime: EndTime,
            };
            that.AddAppointmentCreateScheduleNet(params);
          } else {
            var StartTime_1 = dateTime.formatDate.format(
              that.currentScheduleInfo.currentScheduleTime[0],
              "hh:mm"
            );
            var EndTime_1 = dateTime.formatDate.format(
              that.currentScheduleInfo.currentScheduleTime[1],
              "hh:mm"
            );
            var params_1 = {
              ID: that.currentScheduleInfo.ID,
              Name: that.currentScheduleInfo.Name,
              StartTime: StartTime_1,
              EndTime: EndTime_1,
            };
            that.updateAppointmentScheduleNet(params_1);
          }
        }
      });
    },
    /**   筛选员工   */
    handleSearchSchedule() {
      console.log("搜索");
    },

    /**  网络请求  */
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**  预约设置  */
    /**  获取预约设置   */
    getAppointmentConfigNet() {
      var that = this;
      API.appointmentConfig()
        .then((res) => {
          if (res.StateCode == 200) {
            that.AppointmentConfigInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  保存预约设置   */
    updateAppointmentConfigNet(params) {
      var that = this;
      that.$refs["AppointmentConfigInfoRef"].validate((valid) => {
        if (valid) {
          API.appointmentUpdateConfig(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "预约配置成功",
                  duration: 2000,
                });
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {});
        }
      });
    },
    /**  班次设置  */
    /**  班次列表  */
    getAppointmentScheduleNet() {
      var that = this;
      API.appointmentSchedule()
        .then((res) => {
          if (res.StateCode == 200) {
            that.scheduleList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  新增班次  */
    AddAppointmentCreateScheduleNet(params) {
      var that = this;
      API.appointmentCreateSchedule(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$refs["currentScheduleInfoRef"].resetFields();
            that.$message.success({
              message: "添加班次成功",
              duration: 2000,
            });
            that.currentScheduleInfo = {};
            that.getAppointmentScheduleNet();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  修改班次  */
    updateAppointmentScheduleNet(params) {
      var that = this;
      API.appointmentUpdateSchedule(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$refs["currentScheduleInfoRef"].resetFields();
            that.$message.success({
              message: "修改班次成功",
              duration: 2000,
            });
            that.getAppointmentScheduleNet();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  删除班次  */
    DeleteAppointmentScheduleNet(row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var params = {
            ID: row.ID,
          };
          API.appointmentDeleteSchedule(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.getAppointmentScheduleNet();
                that.$message.error({
                  message: "删除成功",
                  duration: 2000,
                });
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**  员工排班信息  */
    /**  59.10.获取员工排班信息  */
    getAppointmentEmployeeSchedule() {
      var that = this;
      var Month = dateTime.formatDate.format(that.AppointmentMonth, "YYYY-MM");
      if (that.empScheduleForType == "2") {
        Month = dateTime.formatDate.format(
          that.AppointmentLastMonth,
          "YYYY-MM"
        );
      } else {
        Month = dateTime.formatDate.format(that.AppointmentMonth, "YYYY-MM");
      }

      var params = {
        Month: Month,
      };

      API.appointmentEmployeeSchedule(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (that.empScheduleForType == "2") {
              var lastEmpScheduleData = res.Data;
              var tempEmpSchedule = Enumerable.from(lastEmpScheduleData)
                .select((emp) => {
                  var tempSchedule = Enumerable.from(emp.Schedule)
                    .doAction((i) => {
                      var ScheduleDate = dateTime.convertDateFromString(
                        i.ScheduleDate
                      );
                      i.day = dateTime.formatDate.format(ScheduleDate, "DD");
                      i.ScheduleDate = dateTime.formatDate.format(
                        new Date(
                          ScheduleDate.setMonth(ScheduleDate.getMonth() + 1)
                        ),
                        "YYYY-MM-DD"
                      );
                    })
                    .toArray();
                  // 空值 设置为休息状态
                  var IsRestArr = Enumerable.from(that.AppointmentDayAndWeek)
                    .except(tempSchedule, "$.day")
                    .where((i) => (i.IsRest = true))
                    .toArray();
                  tempSchedule = Enumerable.from(IsRestArr)
                    .concat(emp.Schedule)
                    .toArray();
                  emp.Schedule = tempSchedule;
                  return emp;
                })
                .toArray();

              that.saveAppointmentEmployeeSchedule(tempEmpSchedule);
              that.empScheduleForType = "0";
            } else {
              that.empScheduleData = res.Data;
              /**  员工排班数据  */
              that.empScheduleData.forEach((emp) => {
                var temp_week = that.AppointmentDayAndWeek;
                var tempTime = [];
                if (emp.Schedule.length == 0) {
                  temp_week.forEach((time) => {
                    emp.Schedule.push({
                      day: time.day,
                      ScheduleDate: time.ScheduleDate,
                      AppointmentScheduleID: time.AppointmentScheduleID,
                      AppointmentScheduleName: time.AppointmentScheduleName,
                      IsRest: time.IsRest,
                      isSelect: false,
                    });
                  });
                } else {
                  temp_week.forEach((time) => {
                    var tempSch = time;
                    emp.Schedule.forEach((sch) => {
                      var day = sch.ScheduleDate.split("-")[2];
                      if (time.day == day) {
                        tempSch = sch;
                        tempSch.day = day;
                      }
                    });
                    tempTime.push({
                      day: tempSch.day,
                      ScheduleDate: tempSch.ScheduleDate,
                      AppointmentScheduleID: tempSch.AppointmentScheduleID,
                      AppointmentScheduleName: tempSch.AppointmentScheduleName,
                      IsRest: tempSch.IsRest,
                      isSelect: false,
                    });
                  });
                  emp.Schedule = tempTime;
                }
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  保存员工排班信息  */
    saveAppointmentEmployeeSchedule(params) {
      var that = this;
      // var params =
      API.appointmentUpdateEmployeeSchedule(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.getAppointmentEmployeeSchedule();
            // that.$message.error({
            //   message: "删除成功",
            //   duration: 2000,
            // });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },

  mounted() {
    var that = this;
    that.entityData();
    that.AppointmentChangeMonth();
    that.getAppointmentConfigNet();
    that.getAppointmentScheduleNet();
    that.$nextTick(() => {
      that.scheduleTableHeight = window.innerHeight - 250;
    });
  },
};
</script>

<style  lang="scss">
.AppointmentConfig {
  .customTableHeader {
    th {
      padding: 0;
      .cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60px;
        background-color: #f4fbff;
        padding: 0px;
      }
    }
  }
  .customTableCellClass {
    padding: 0px !important;
    .cell {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 40px;
      font-weight: 300;
      -webkit-touch-callout: none; /*系统默认菜单被禁用*/
      -webkit-user-select: none; /*webkit浏览器*/
      -khtml-user-select: none; /*早期浏览器*/
      -moz-user-select: none; /*火狐*/
      -ms-user-select: none; /*IE10*/
      user-select: none;
    }
  }

  /**  设置对角线  */
  .tabHeaderDiagonal {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 1px;
    background-color: #ebeef5;
    display: block;
    text-align: center;
    transform: rotate(34deg);
    transform-origin: top left;
    -ms-transform: rotate(34deg);
    -ms-transform-origin: top left;
    -webkit-transform: rotate(34deg);
    -webkit-transform-origin: top left;
  }
  .tabHeaderDiagonal:before {
    content: "日期";
    font-size: 13px;
    text-align: left;
    position: absolute;
    width: 50px;
    bottom: 0px;
    right: 10px;
    transform-origin: top left;
    transform: rotate(-34deg);
    -ms-transform-origin: top left;
    -ms-transform: rotate(-34deg);
    -webkit-transform-origin: top left;
    -webkit-transform: rotate(-34deg);
  }

  .tabHeaderDiagonal:after {
    content: "接待人";
    font-size: 13px;
    text-align: right;
    position: absolute;
    width: 70px;
    top: 40px;
    left: 0;

    transform-origin: top left;
    transform: rotate(-34deg);
    -ms-transform-origin: top left;
    -ms-transform: rotate(-34deg);
    -webkit-transform-origin: top left;
    -webkit-transform: rotate(-34deg);
  }

  .cell_content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
  }

  .cell_select_content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #eeeeee;
    font-size: 12px;
  }

  .empIsRest {
    color: #999999;
  }

  .empIsRestMistake {
    color: #409eff;
  }
}
</style>